import React, { useContext, useEffect, useRef } from "react"
import HelmetHead from "../../components/HelmetHead"
import LayoutContext from "../../components/LayoutContext"

const Datenschutz = () => {
  const [layout, setLayout] = useContext(LayoutContext)

  useEffect(() => {
    setLayout(state => ({
      ...state,
      header: {
        fixed: false,
        bgColor: "white",
        color: "light",
      },
    }))
  }, [])
  return (
    <>
      <HelmetHead
        title="Datenschutz Maklerzentrale"
        metas={[{ name: "robots", content: "noindex" }]}
      />
      <section className="bg-light pt-8 pt-md-11 pb-8">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md">
              <h1 className="display-4 mb-2">Datenschutzerklärung</h1>
            </div>
            <div className="col-auto">
              <p className="fs-lg text-secondary mb-md-0">
                Stand: 21. Juli 2020
              </p>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-sm btn-primary mt-4"
            onClick={() => {
              typeof window !== `undefined` &&
                window.cookiehub &&
                window.cookiehub.openSettings()
            }}
          >
            Cookie-Einstellungen
          </button>
          <div className="row">
            <div className="col-12">
              <hr className="my-6 my-md-8" />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-8 mb-5">
              <blockquote>
                Wir freuen uns über Ihr Interesse an unserem Unternehmen und
                über Ihren Besuch und unserer Webseite www.maklerzentrale.com.
                Es ist uns ein besonders wichtiges Anliegen den Schutz Ihrer
                personenbezogenen Daten zu gewährleisten.
                <br />
                Personenbezogene Daten sind Informationen über persönliche oder
                sachliche Verhältnisse einer Person. Welche personenbezogenen
                Informationen wir während Ihres Besuches auf unserer Webeseite
                erfassen und wie wir diese Informationen nutzen stellen wir
                Ihnen nachfolgend dar.
              </blockquote>
              <p>
                Personenbezogene Daten (nachfolgend zumeist nur „Daten“ genannt)
                werden von uns nur im Rahmen der Erforderlichkeit sowie zum
                Zwecke der Bereitstellung eines funktionsfähigen und
                nutzerfreundlichen Internetauftritts, inklusive seiner Inhalte
                und der dort angebotenen Leistungen, verarbeitet.
              </p>
              <p>
                Gemäß Art. 4 Ziffer 1. der Verordnung (EU) 2016/679, also der
                Datenschutz-Grundverordnung (nachfolgend nur „DSGVO“ genannt),
                gilt als „Verarbeitung“ jeder mit oder ohne Hilfe
                automatisierter Verfahren ausgeführter Vorgang oder jede solche
                Vorgangsreihe im Zusammenhang mit personenbezogenen Daten, wie
                das Erheben, das Erfassen, die Organisation, das Ordnen, die
                Speicherung, die Anpassung oder Veränderung, das Auslesen, das
                Abfragen, die Verwendung, die Offenlegung durch Übermittlung,
                Verbreitung oder eine andere Form der Bereitstellung, den
                Abgleich oder die Verknüpfung, die Einschränkung, das Löschen
                oder die Vernichtung.
              </p>
              <p>
                Mit der nachfolgenden Datenschutzerklärung informieren wir Sie
                insbesondere über Art, Umfang, Zweck, Dauer und Rechtsgrundlage
                der Verarbeitung personenbezogener Daten, soweit wir entweder
                allein oder gemeinsam mit anderen über die Zwecke und Mittel der
                Verarbeitung entscheiden. Zudem informieren wir Sie nachfolgend
                über die von uns zu Optimierungszwecken sowie zur Steigerung der
                Nutzungsqualität eingesetzten Fremdkomponenten, soweit hierdurch
                Dritte Daten in wiederum eigener Verantwortung verarbeiten.
              </p>
              <p>Unsere Datenschutzerklärung ist wie folgt gegliedert:</p>
              <p>
                I. Informationen über uns als Verantwortliche
                <br />
                II. Rechte der Nutzer und Betroffenen
                <br />
                III. Informationen zur Datenverarbeitung
              </p>
              <h3>I. Informationen über uns als Verantwortliche</h3>
              <p className="text-muted">
                Verantwortlicher Anbieter dieses Internetauftritts im
                datenschutzrechtlichen Sinne ist:
              </p>
              <p className="text-muted">
                Albino Cipolla & Tobias Brauchle
                <br />
                Nahestraße 5<br />
                55118 Mainz
                <br />
                Deutschland
              </p>
              <p className="text-muted">
                Telefon: +49 800 100 10 01
                <br />
                E-Mail: datenschutz@maklerzentrale.com
              </p>
              <p className="text-muted">
                Vertreten durch die Gesellschafter: Albino Cipolla, Tobias
                Brauchle
              </p>
              <h3>II. Rechte der Nutzer und Betroffenen</h3>
              <p className="text-muted">
                Mit Blick auf die nachfolgend noch näher beschriebene
                Datenverarbeitung haben die Nutzer und Betroffenen das Recht
              </p>
              <ul className="text-muted">
                <li>
                  auf Bestätigung, ob sie betreffende Daten verarbeitet werden,
                  auf Auskunft über die verarbeiteten Daten, auf weitere
                  Informationen über die Datenverarbeitung sowie auf Kopien der
                  Daten (vgl. auch Art. 15 DSGVO);
                </li>
                <li>
                  auf Berichtigung oder Vervollständigung unrichtiger bzw.
                  unvollständiger Daten (vgl. auch Art. 16 DSGVO);
                </li>
                <li>
                  auf unverzügliche Löschung der sie betreffenden Daten (vgl.
                  auch Art. 17 DSGVO), oder, alternativ, soweit eine weitere
                  Verarbeitung gemäß Art. 17 Abs. 3 DSGVO erforderlich ist, auf
                  Einschränkung der Verarbeitung nach Maßgabe von Art. 18 DSGVO;
                </li>
                <li>
                  auf Erhalt der sie betreffenden und von ihnen bereitgestellten
                  Daten und auf Übermittlung dieser Daten an andere
                  Anbieter/Verantwortliche (vgl. auch Art. 20 DSGVO);
                </li>
                <li>
                  auf Beschwerde gegenüber der Aufsichtsbehörde, sofern sie der
                  Ansicht sind, dass die sie betreffenden Daten durch den
                  Anbieter unter Verstoß gegen datenschutzrechtliche
                  Bestimmungen verarbeitet werden (vgl. auch Art. 77 DSGVO).
                </li>
              </ul>
              <p className="text-muted">
                Darüber hinaus ist der Anbieter dazu verpflichtet, alle
                Empfänger, denen gegenüber Daten durch den Anbieter offengelegt
                worden sind, über jedwede Berichtigung oder Löschung von Daten
                oder die Einschränkung der Verarbeitung, die aufgrund der
                Artikel 16, 17 Abs. 1, 18 DSGVO erfolgt, zu unterrichten. Diese
                Verpflichtung besteht jedoch nicht, soweit diese Mitteilung
                unmöglich oder mit einem unverhältnismäßigen Aufwand verbunden
                ist. Unbeschadet dessen hat der Nutzer ein Recht auf Auskunft
                über diese Empfänger.
              </p>
              <p className="text-muted">
                <strong>
                  Ebenfalls haben die Nutzer und Betroffenen nach Art. 21 DSGVO
                  das Recht auf Widerspruch gegen die künftige Verarbeitung der
                  sie betreffenden Daten, sofern die Daten durch den Anbieter
                  nach Maßgabe von Art. 6 Abs. 1 lit. f) DSGVO verarbeitet
                  werden. Insbesondere ist ein Widerspruch gegen die
                  Datenverarbeitung zum Zwecke der Direktwerbung statthaft.
                </strong>
              </p>
              <h3>III. Informationen zur Datenverarbeitung</h3>
              <p className="text-muted">
                Ihre bei Nutzung unseres Internetauftritts verarbeiteten Daten
                werden gelöscht oder gesperrt, sobald der Zweck der Speicherung
                entfällt, der Löschung der Daten keine gesetzlichen
                Aufbewahrungspflichten entgegenstehen und nachfolgend keine
                anderslautenden Angaben zu einzelnen Verarbeitungsverfahren
                gemacht werden.
              </p>
              <h4>Serverdaten</h4>
              <p className="text-muted">
                Aus technischen Gründen, insbesondere zur Gewährleistung eines
                sicheren und stabilen Internetauftritts, werden Daten durch
                Ihren Internet-Browser an uns bzw. an unseren Webspace-Provider
                übermittelt. Mit diesen sog. Server-Logfiles werden u.a. Typ und
                Version Ihres Internetbrowsers, das Betriebssystem, die Website,
                von der aus Sie auf unseren Internetauftritt gewechselt haben
                (Referrer URL), die Website(s) unseres Internetauftritts, die
                Sie besuchen, Datum und Uhrzeit des jeweiligen Zugriffs sowie
                die IP-Adresse des Internetanschlusses, von dem aus die Nutzung
                unseres Internetauftritts erfolgt, erhoben.
              </p>
              <p className="text-muted">
                Diese so erhobenen Daten werden vorrübergehend gespeichert, dies
                jedoch nicht gemeinsam mit anderen Daten von Ihnen.
              </p>
              <p className="text-muted">
                Diese Speicherung erfolgt auf der Rechtsgrundlage von Art. 6
                Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der
                Verbesserung, Stabilität, Funktionalität und Sicherheit unseres
                Internetauftritts.
              </p>
              <p className="text-muted">
                Die Daten werden spätestens nach sieben Tage wieder gelöscht,
                soweit keine weitere Aufbewahrung zu Beweiszwecken erforderlich
                ist. Andernfalls sind die Daten bis zur endgültigen Klärung
                eines Vorfalls ganz oder teilweise von der Löschung ausgenommen.
              </p>
              <h4>Cookies</h4>
              <h5>a) Sitzungs-Cookies/Session-Cookies</h5>
              <p className="text-muted">
                Wir verwenden mit unserem Internetauftritt sog. Cookies. Cookies
                sind kleine Textdateien oder andere Speichertechnologien, die
                durch den von Ihnen eingesetzten Internet-Browser auf Ihrem
                Endgerät ablegt und gespeichert werden. Durch diese Cookies
                werden im individuellen Umfang bestimmte Informationen von
                Ihnen, wie beispielsweise Ihre Browser- oder Standortdaten oder
                Ihre IP-Adresse, verarbeitet. &nbsp;
              </p>
              <p className="text-muted">
                Durch diese Verarbeitung wird unser Internetauftritt
                benutzerfreundlicher, effektiver und sicherer, da die
                Verarbeitung bspw. die Wiedergabe unseres Internetauftritts in
                unterschiedlichen Sprachen oder das Angebot einer
                Warenkorbfunktion ermöglicht.
              </p>
              <p className="text-muted">
                Rechtsgrundlage dieser Verarbeitung ist Art. 6 Abs. 1 lit b.)
                DSGVO, sofern diese Cookies Daten zur Vertragsanbahnung oder
                Vertragsabwicklung verarbeitet werden.
              </p>
              <p className="text-muted">
                Falls die Verarbeitung nicht der Vertragsanbahnung oder
                Vertragsabwicklung dient, liegt unser berechtigtes Interesse in
                der Verbesserung der Funktionalität unseres Internetauftritts.
                Rechtsgrundlage ist in dann Art. 6 Abs. 1 lit. f) DSGVO.
              </p>
              <p className="text-muted">
                Mit Schließen Ihres Internet-Browsers werden diese
                Session-Cookies gelöscht.
              </p>
              <h5>b) Drittanbieter-Cookies</h5>
              <p className="text-muted">
                Gegebenenfalls werden mit unserem Internetauftritt auch Cookies
                von Partnerunternehmen, mit denen wir zum Zwecke der Werbung,
                der Analyse oder der Funktionalitäten unseres Internetauftritts
                zusammenarbeiten, verwendet.
              </p>
              <p className="text-muted">
                Die Einzelheiten hierzu, insbesondere zu den Zwecken und den
                Rechtsgrundlagen der Verarbeitung solcher Drittanbieter-Cookies,
                entnehmen Sie bitte den nachfolgenden Informationen.
              </p>
              <h5>c) Beseitigungsmöglichkeit</h5>
              <p className="text-muted">
                Sie können die Installation der Cookies durch eine Einstellung
                Ihres Internet-Browsers verhindern oder einschränken. Ebenfalls
                können Sie bereits gespeicherte Cookies jederzeit löschen. Die
                hierfür erforderlichen Schritte und Maßnahmen hängen jedoch von
                Ihrem konkret genutzten Internet-Browser ab. Bei Fragen benutzen
                Sie daher bitte die Hilfefunktion oder Dokumentation Ihres
                Internet-Browsers oder wenden sich an dessen Hersteller bzw.
                Support. Bei sog. Flash-Cookies kann die Verarbeitung allerdings
                nicht über die Einstellungen des Browsers unterbunden werden.
                Stattdessen müssen Sie insoweit die Einstellung Ihres
                Flash-Players ändern. Auch die hierfür erforderlichen Schritte
                und Maßnahmen hängen von Ihrem konkret genutzten Flash-Player
                ab. Bei Fragen benutzen Sie daher bitte ebenso die Hilfefunktion
                oder Dokumentation Ihres Flash-Players oder wenden sich an den
                Hersteller bzw. Benutzer-Support.
              </p>
              <p className="text-muted">
                Sollten Sie die Installation der Cookies verhindern oder
                einschränken, kann dies allerdings dazu führen, dass nicht
                sämtliche Funktionen unseres Internetauftritts vollumfänglich
                nutzbar sind.{" "}
              </p>
              <h4>Vertragsabwicklung</h4>
              <p className="text-muted">
                Die von Ihnen zur Inanspruchnahme unseres Waren- und/oder
                Dienstleistungsangebots übermittelten Daten werden von uns zum
                Zwecke der Vertragsabwicklung verarbeitet und sind insoweit
                erforderlich. Vertragsschluss und Vertragsabwicklung sind ohne
                Bereitstellung Ihrer Daten nicht möglich.
              </p>
              <p className="text-muted">
                Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs. 1 lit. b)
                DSGVO.
              </p>
              <p className="text-muted">
                Wir löschen die Daten mit vollständiger Vertragsabwicklung,
                müssen dabei aber die steuer- und handelsrechtlichen
                Aufbewahrungsfristen beachten.
              </p>
              <p className="text-muted">
                Im Rahmen der Vertragsabwicklung geben wir Ihre Daten an das mit
                der Warenlieferung beauftragte Transportunternehmen oder an den
                Finanzdienstleister weiter, soweit die Weitergabe zur
                Warenauslieferung oder zu Bezahlzwecken erforderlich ist.
              </p>
              <p className="text-muted">
                Rechtsgrundlage für die Weitergabe der Daten ist dann Art. 6
                Abs. 1 lit. b) DSGVO.
              </p>
              <h4>Kundenkonto / Registrierungsfunktion</h4>
              <p className="text-muted">
                Falls Sie über unseren Internetauftritt ein Kundenkonto bei uns
                anlegen, werden wir die von Ihnen bei der Registrierung
                eingegebenen Daten (also bspw. Ihren Namen, Ihre Anschrift oder
                Ihre E-Mail-Adresse) ausschließlich für vorvertragliche
                Leistungen, für die Vertragserfüllung oder zum Zwecke der
                Kundenpflege (bspw. um Ihnen eine Übersicht über Ihre bisherigen
                Bestellungen bei uns zur Verfügung zu stellen oder um Ihnen die
                sog. Merkzettelfunktion anbieten zu können) erheben und
                speichern. Gleichzeitig speichern wir dann die IP-Adresse und
                das Datum Ihrer Registrierung nebst Uhrzeit. Eine Weitergabe
                dieser Daten an Dritte erfolgt natürlich nicht.
              </p>
              <p className="text-muted">
                Im Rahmen des weiteren Anmeldevorgangs wird Ihre Einwilligung in
                diese Verarbeitung eingeholt und auf diese Datenschutzerklärung
                verwiesen. Die dabei von uns erhobenen Daten werden
                ausschließlich für die Zurverfügungstellung des Kundenkontos
                verwendet.&nbsp;
              </p>
              <p className="text-muted">
                Soweit Sie in diese Verarbeitung einwilligen, ist Art. 6 Abs. 1
                lit. a) DSGVO Rechtsgrundlage für die Verarbeitung.
              </p>
              <p className="text-muted">
                Sofern die Eröffnung des Kundenkontos zusätzlich auch
                vorvertraglichen Maßnahmen oder der Vertragserfüllung dient, so
                ist Rechtsgrundlage für diese Verarbeitung auch noch Art. 6 Abs.
                1 lit. b) DSGVO.
              </p>
              <p className="text-muted">
                Die uns erteilte Einwilligung in die Eröffnung und den Unterhalt
                des Kundenkontos können Sie gemäß Art. 7 Abs. 3 DSGVO jederzeit
                mit Wirkung für die Zukunft widerrufen. Hierzu müssen Sie uns
                lediglich über Ihren Widerruf in Kenntnis setzen.
              </p>
              <p className="text-muted">
                Die insoweit erhobenen Daten werden gelöscht, sobald die
                Verarbeitung nicht mehr erforderlich ist. Hierbei müssen wir
                aber steuer- und handelsrechtliche Aufbewahrungsfristen
                beachten.
              </p>
              <h4>Newsletter</h4>
              <p className="text-muted">
                Falls Sie sich für unseren kostenlosen Newsletter anmelden,
                werden die von Ihnen hierzu abgefragten Daten, also Ihre
                E-Mail-Adresse sowie - optional - Ihr Name und Ihre Anschrift,
                an uns übermittelt. Gleichzeitig speichern wir die IP-Adresse
                des Internetanschlusses von dem aus Sie auf unseren
                Internetauftritt zugreifen sowie Datum und Uhrzeit Ihrer
                Anmeldung. Im Rahmen des weiteren Anmeldevorgangs werden wir
                Ihre Einwilligung in die Übersendung des Newsletters einholen,
                den Inhalt konkret beschreiben und auf diese
                Datenschutzerklärung verwiesen. Die dabei erhobenen Daten
                verwenden wir ausschließlich für den Newsletter-Versand – sie
                werden deshalb insbesondere auch nicht an Dritte weitergegeben.
              </p>
              <p className="text-muted">
                Rechtsgrundlage hierbei ist Art. 6 Abs. 1 lit. a) DSGVO.
              </p>
              <p className="text-muted">
                Die Einwilligung in den Newsletter-Versand können Sie gemäß Art.
                7 Abs. 3 DSGVO jederzeit mit Wirkung für die Zukunft widerrufen.
                Hierzu müssen Sie uns lediglich über Ihren Widerruf in Kenntnis
                setzen oder den in jedem Newsletter enthaltenen Abmeldelink
                betätigen.
              </p>
              <h4>Kontaktanfragen / Kontaktmöglichkeit</h4>
              <p className="text-muted">
                Sofern Sie per Kontaktformular oder E-Mail mit uns in Kontakt
                treten, werden die dabei von Ihnen angegebenen Daten zur
                Bearbeitung Ihrer Anfrage genutzt. Die Angabe der Daten ist zur
                Bearbeitung und Beantwortung Ihre Anfrage erforderlich - ohne
                deren Bereitstellung können wir Ihre Anfrage nicht oder
                allenfalls eingeschränkt beantworten.
              </p>
              <p className="text-muted">
                Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 lit. b)
                DSGVO.
              </p>
              <p className="text-muted">
                Ihre Daten werden gelöscht, sofern Ihre Anfrage abschließend
                beantwortet worden ist und der Löschung keine gesetzlichen
                Aufbewahrungspflichten entgegenstehen, wie bspw. bei einer sich
                etwaig anschließenden Vertragsabwicklung.
              </p>
              <h4>Nutzerbeiträge, Kommentare und Bewertungen</h4>
              <p className="text-muted">
                Wir bieten Ihnen an, auf unseren Internetseiten Fragen,
                Antworten, Meinungen oder Bewertungen, nachfolgend nur „Beiträge
                genannt, zu veröffentlichen. Sofern Sie dieses Angebot in
                Anspruch nehmen, verarbeiten und veröffentlichen wir Ihren
                Beitrag, Datum und Uhrzeit der Einreichung sowie das von Ihnen
                ggf. genutzte Pseudonym.
              </p>
              <p className="text-muted">
                Rechtsgrundlage hierbei ist Art. 6 Abs. 1 lit. a) DSGVO. Die
                Einwilligung können Sie gemäß Art. 7 Abs. 3 DSGVO jederzeit mit
                Wirkung für die Zukunft widerrufen. Hierzu müssen Sie uns
                lediglich über Ihren Widerruf in Kenntnis setzen.
              </p>
              <p className="text-muted">
                Darüber hinaus verarbeiten wir auch Ihre IP- und E-Mail-Adresse.
                Die IP-Adresse wird verarbeitet, weil wir ein berechtigtes
                Interesse daran haben, weitere Schritte einzuleiten oder zu
                unterstützen, sofern Ihr Beitrag in Rechte Dritter eingreift
                und/oder er sonst wie rechtswidrig erfolgt.
              </p>
              <p className="text-muted">
                Rechtsgrundlage ist in diesem Fall Art. 6 Abs. 1 lit. f) DSGVO.
                Unser berechtigtes Interesse liegt in der ggf. notwendigen
                Rechtsverteidigung.
              </p>
              <h4>Abonnement von Beiträgen</h4>
              <p className="text-muted">
                Sofern Sie Beiträge auf unseren Internetseiten veröffentlichen,
                bieten wir Ihnen zusätzlich an, etwaige Folgebeiträge Dritter zu
                abonnieren. Um Sie über diese Folgebeiträge per E-Mail
                informieren zu können, verarbeiten wir Ihre E-Mail-Adresse.
              </p>
              <p className="text-muted">
                Rechtsgrundlage hierbei ist Art. 6 Abs. 1 lit. a) DSGVO. Die
                Einwilligung in dieses Abonnement können Sie gemäß Art. 7 Abs. 3
                DSGVO jederzeit mit Wirkung für die Zukunft widerrufen. Hierzu
                müssen Sie uns lediglich über Ihren Widerruf in Kenntnis setzen
                oder den in der jeweiligen E-Mail enthaltenen Abmeldelink
                betätigen.
              </p>
              <h4>Lead-Generator</h4>
              <p className="text-muted">
                Damit der Lead-Generator durchgeführt werden kann, werden die
                für die Aufnahme und Durchführung der Analyse erforderlichen
                Angaben von Ihnen erhoben und verarbeitet. Alle von Ihnen
                gemachten Angaben sowie Kontaktdaten zur Erstellung der Analyse
                und der anschliessenden Übermittlung der erstellten Analyse
                dürfen an die von Ihnen angegebene Postadresse oder E-Mail
                Adresse übermittelt werden. Ferner können von der Maklerzentrale
                ausgewählte Immobilienexperten Sie kontaktieren und erwünschte
                Informationen zu Ihrer Immobilie detaillierter erläutern.
              </p>
              <h4>Agenturdienstleistungen</h4>
              <p className="text-muted">
                Wir verarbeiten die Daten unserer Kunden gem. den
                datenschutzrechtlichen Bestimmungen des Bundes
                (Datenschutzgesetz, DSG) und der EU-DSGVO im Rahmen unserer
                vertraglichen Leistungen.
              </p>
              <p className="text-muted">
                Hierbei verarbeiten wir Bestandsdaten (z.B., Kundenstammdaten,
                wie Namen oder Adressen), Kontaktdaten (z.B. E-Mail,
                Telefonnummern), Inhaltsdaten (z.B. Texteingaben etc.),
                Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit), Zahlungsdaten
                (z.B. Bankverbindung, Zahlungshistorie), Nutzungs- und Metadaten
                (z.B. im Rahmen der Auswertung und Erfolgsmessung von
                Marketingmassnahmen). Zu den Betroffenen gehören unsere Kunden,
                Interessenten sowie deren Kunden, Nutzer, Websitebesucher oder
                Mitarbeiter sowie Dritte. Der Zweck der Verarbeitung besteht in
                der Erbringung von Vertragsleistungen, Abrechnung und unserem
                Kundenservice. Die Rechtsgrundlagen der Verarbeitung ergeben
                sich aus Art. 6 Abs. 1 lit. b DSGVO (vertragliche Leistungen),
                Art. 6 Abs. 1 lit. f DSGVO (Analyse, Statistik, Optimierung,
                Sicherheitsmassnahmen). Wir verarbeiten Daten, die zur
                Begründung und Erfüllung der vertraglichen Leistungen
                erforderlich sind und weisen auf die Erforderlichkeit ihrer
                Angabe hin. Eine Offenlegung an Externe erfolgt nur, wenn sie im
                Rahmen eines Auftrags erforderlich ist. Bei der Verarbeitung der
                uns im Rahmen eines Auftrags überlassenen Daten handeln wir
                entsprechend den Weisungen der Auftraggeber sowie der
                gesetzlichen Vorgaben einer Auftragsverarbeitung gem. Art. 28
                DSGVO und verarbeiten die Daten zu keinen anderen, als den
                auftragsgemässen Zwecken.
              </p>
              <p className="text-muted">
                Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs-
                und vergleichbarer Pflichten. Die Erforderlichkeit der
                Aufbewahrung der Daten wird in unregelmässigen Abständen
                überprüft. Im Fall der gesetzlichen Archivierungspflichten
                erfolgt die Löschung nach deren Ablauf. Im Fall von Daten, die
                uns gegenüber im Rahmen eines Auftrags durch den Auftraggeber
                offengelegt wurden, löschen wir die Daten entsprechend den
                Vorgaben des Auftrags, grundsätzlich nach Ende des Auftrags.
              </p>
              <h4>Maklerleistungen</h4>
              <p className="text-muted">
                Wir verarbeiten die Daten unserer Kunden, Klienten und
                Interessenten (einheitlich bezeichnet als «Kunden») gem. den
                datenschutzrechtlichen Bestimmungen des Bundes
                (Datenschutzgesetz, DSG) und der EU-DSGVO entsprechend Art. 6
                Abs. 1 lit. b. DSGVO, um ihnen gegenüber unsere vertraglichen
                oder vorvertraglichen Leistungen zu erbringen. Die hierbei
                verarbeiteten Daten, die Art, der Umfang und der Zweck und die
                Erforderlichkeit ihrer Verarbeitung bestimmen sich nach dem
                zugrundeliegenden Auftrag. Dazu gehören grundsätzlich Bestands-
                und Stammdaten der Kunden (Name, Adresse, etc.), als auch die
                Kontaktdaten (E-Mailadresse, Telefon, etc.), die Vertragsdaten
                (Inhalt der Beauftragung, Entgelte, Laufzeiten, Angaben zu den
                vermittelten Unternehmen/ Versicherern/ Leistungen) und
                Zahlungsdaten (Provisionen, Zahlungshistorie, etc.). Wir können
                ferner die Angaben zu den Eigenschaften und Umständen von
                Personen oder ihnen gehörenden Sachen verarbeiten, wenn dies zum
                Gegenstand unseres Auftrags gehört. Dies können z.B. Angaben zu
                persönlichen Lebensumständen, mobilen oder immobilen Sachgütern
                sein.
              </p>
              <p className="text-muted">
                Im Rahmen unserer Beauftragung kann es auch erforderlich sein,
                dass wir besondere Kategorien von Daten gem. Art. 9 Abs. 1
                DSGVO, hier insbesondere Angaben zur Gesundheit einer Person
                verarbeiten. Hierzu holen wir, sofern erforderlich, gem. Art. 6
                Abs. 1 lit a., Art. 7, Art. 9 Abs. 2 lit. a DSGVO eine
                ausdrückliche Einwilligung der Kunden ein.
              </p>
              <p className="text-muted">
                Sofern für die Vertragserfüllung oder gesetzlich erforderlich,
                offenbaren oder übermitteln wir die Daten der Kunden im Rahmen
                von Deckungsanfragen, Abschlüssen und Abwicklungen von
                Verträgen, Daten an Anbieter der vermittelten Leistungen/
                Objekte, Versicherer, Rückversicherer, Maklerpools, technische
                Dienstleister, sonstige Dienstleister, wie z.B. kooperierende
                Verbände, sowie Finanzdienstleister, Kreditinstitute und
                Kapitalanlagegesellschaften sowie Sozialversicherungsträger,
                Steuerbehörden, Steuerberater, Rechtsberater, Wirtschaftsprüfer,
                Versicherungs-Ombudsmänner und die Schweizerische
                Finanzmarkt-Aufsicht (FINMA) oder Bundesanstalt für
                Finanzdienstleistungsaufsicht (BaFin). Ferner können wir
                Unterauftragnehmer beauftragen, wie z.B. Untervermittler. Wir
                holen eine Einwilligung der Kunden ein, sofern diese zur
                Offenbarung/ Übermittlung eine Einwilligung der Kunden
                erforderlich ist (was z.B. im Fall von besonderen Kategorien von
                Daten gem. Art. 9 DSGVO der Fall sein kann).
              </p>
              <p className="text-muted">
                Die Löschung der Daten erfolgt nach Ablauf gesetzlicher
                Gewährleistungs- und vergleichbarer Pflichten, wobei die
                Erforderlichkeit der Aufbewahrung der Daten in unregelmässigen
                Abständen überprüft wird. Im Übrigen gelten die gesetzlichen
                Aufbewahrungspflichten. Im Fall der gesetzlichen
                Archivierungspflichten erfolgt die Löschung nach deren Ablauf.
              </p>
              <h4>Live-Chat</h4>
              <p className="text-muted">
                Diese Website ermöglicht es Ihnen, über die Verwendung der
                Funktion „Live-Chat“ Kontakt zu der Maklerzentrale aufzu¬nehmen.
                Der Live Chat wird im Auftrag von der Maklerzentrale von
                crisp.chat bereitgestellt.
                <br />
                Bei Verwendung des Chat-Assistenten werden neben den von Ihnen
                eingegebenen Informationen zudem folgende Daten gespeichert, um
                Missbrauch vorzubeugen: Datum und Uhrzeit, IP-Adresse, E-Mail
                Adresse, Nachrichtenverlauf
                <br />
                <br />
                Rechtsgrundlage für die Verarbeitung der Daten ist Art. 6 Abs. 1
                Satz 1 lit. b und f DSGVO.
                <br />
                Sie können die Nutzung der Chat-Funktion zu jeder Zeit
                unterbinden und deaktivieren in den "Cookie-Einstellungen" siehe
                oben.
              </p>
              <h4>IFrames</h4>
              <p className="text-muted">
                Diese Website verwendet die Einbindung von IFrames. Dadurch kann
                z.B die Nutzung der Wohnmarkt-Karte ermöglicht werden.
                <br />
                Bei einem IFrame wird die Besucherseite des Nutzers und die
                IP-Adresse übermittelt. Es wird eine externe Seite eingebunden,
                die auch Cookies setzen kann. Die Inhalte werden regelmäßig auf
                Sorgfalt geprüft, können sich aber aufgrund fehlender
                Verwaltungs-/Eingriffsmöglichkeit ändern. Wir weisen darauf hin,
                wann ein IFrame eingebunden wird. Weitere Informartionen finden
                Sie auch auf der Webseite der Betreiber.
              </p>
              <h4>Google Analytics</h4>
              <p className="text-muted">
                In unserem Internetauftritt setzen wir Google Analytics ein.
                Hierbei handelt es sich um einen Webanalysedienst der Google
                LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA,
                nachfolgend nur „Google“ genannt.
              </p>
              <p className="text-muted">
                Durch die Zertifizierung nach dem EU-US-Datenschutzschild
                („EU-US Privacy Shield“)
              </p>
              <p className="text-muted">
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"
                >
                  https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
                </a>
              </p>
              <p className="text-muted">
                garantiert Google, dass die Datenschutzvorgaben der EU auch bei
                der Verarbeitung von Daten in den USA eingehalten werden.
              </p>
              <p className="text-muted">
                Der Dienst Google Analytics dient zur Analyse des
                Nutzungsverhaltens unseres Internetauftritts. Rechtsgrundlage
                ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse
                liegt in der Analyse, Optimierung und dem wirtschaftlichen
                Betrieb unseres Internetauftritts.
              </p>
              <p className="text-muted">
                Nutzungs- und nutzerbezogene Informationen, wie bspw.
                IP-Adresse, Ort, Zeit oder Häufigkeit des Besuchs unseres
                Internetauftritts, werden dabei an einen Server von Google in
                den USA übertragen und dort gespeichert. Allerdings nutzen wir
                Google Analytics mit der sog. Anonymisierungsfunktion. Durch
                diese Funktion kürzt Google die IP-Adresse schon innerhalb der
                EU bzw. des EWR.
              </p>
              <p className="text-muted">
                Die so erhobenen Daten werden wiederum von Google genutzt, um
                uns eine Auswertung über den Besuch unseres Internetauftritts
                sowie über die dortigen Nutzungsaktivitäten zur Verfügung zu
                stellen. Auch können diese Daten genutzt werden, um weitere
                Dienstleistungen zu erbringen, die mit der Nutzung unseres
                Internetauftritts und der Nutzung des Internets zusammenhängen.
              </p>
              <p className="text-muted">
                Google gibt an, Ihre IP-Adresse nicht mit anderen Daten zu
                verbinden. Zudem hält Google unter
              </p>
              <p className="text-muted">
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="https://www.google.com/intl/de/policies/privacy/partners"
                >
                  https://www.google.com/intl/de/policies/privacy/partners
                </a>
              </p>
              <p className="text-muted">
                weitere datenschutzrechtliche Informationen für Sie bereit, so
                bspw. auch zu den Möglichkeiten, die Datennutzung zu
                unterbinden.
              </p>
              <p className="text-muted">Zudem bietet Google unter</p>
              <p className="text-muted">
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="https://tools.google.com/dlpage/gaoptout?hl=de"
                >
                  https://tools.google.com/dlpage/gaoptout?hl=de
                </a>
              </p>
              <p className="text-muted">
                ein sog. Deaktivierungs-Add-on nebst weiteren Informationen
                hierzu an. Dieses Add-on lässt sich mit den gängigen
                Internet-Browsern installieren und bietet Ihnen weitergehende
                Kontrollmöglichkeit über die Daten, die Google bei Aufruf
                unseres Internetauftritts erfasst. Dabei teilt das Add-on dem
                JavaScript (ga.js) von Google Analytics mit, dass Informationen
                zum Besuch unseres Internetauftritts nicht an Google Analytics
                übermittelt werden sollen. Dies verhindert aber nicht, dass
                Informationen an uns oder an andere Webanalysedienste
                übermittelt werden. Ob und welche weiteren Webanalysedienste von
                uns eingesetzt werden, erfahren Sie natürlich ebenfalls in
                dieser Datenschutzerklärung.
              </p>
              <p className="text-muted">
                Sie können die Erfassung Ihrer Daten durch Google Analytics
                verhindern, indem Sie auf folgenden Link klicken. Es wird ein
                Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei
                zukünftigen Besuchen dieser Website verhindert:{" "}
                <a
                  href="#"
                  onClick={() => {
                    typeof window !== `undefined` &&
                      window.cookiehub &&
                      window.cookiehub.denyAll()
                  }}
                >
                  Google Analytics deaktivieren
                </a>
                .
              </p>
              <h4>Google-Maps</h4>
              <p className="text-muted">
                In unserem Internetauftritt setzen wir Google Maps zur
                Darstellung unseres Standorts sowie zur Erstellung einer
                Anfahrtsbeschreibung ein. Es handelt sich hierbei um einen
                Dienst der Google LLC, 1600 Amphitheatre Parkway, Mountain View,
                CA 94043 USA, nachfolgend nur „Google“ genannt.
              </p>
              <p className="text-muted">
                Durch die Zertifizierung nach dem EU-US-Datenschutzschild
                („EU-US Privacy Shield“)
              </p>
              <p className="text-muted">
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"
                >
                  https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
                </a>
              </p>
              <p className="text-muted">
                garantiert Google, dass die Datenschutzvorgaben der EU auch bei
                der Verarbeitung von Daten in den USA eingehalten werden.
              </p>
              <p className="text-muted">
                Um die Darstellung bestimmter Schriften in unserem
                Internetauftritt zu ermöglichen, wird bei Aufruf unseres
                Internetauftritts eine Verbindung zu dem Google-Server in den
                USA aufgebaut.
              </p>
              <p className="text-muted">
                Sofern Sie die in unseren Internetauftritt eingebundene
                Komponente Google Maps aufrufen, speichert Google über Ihren
                Internet-Browser ein Cookie auf Ihrem Endgerät. Um unseren
                Standort anzuzeigen und eine Anfahrtsbeschreibung zu erstellen,
                werden Ihre Nutzereinstellungen und -daten verarbeitet. Hierbei
                können wir nicht ausschließen, dass Google Server in den USA
                einsetzt.
              </p>
              <p className="text-muted">
                Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser
                berechtigtes Interesse liegt in der Optimierung der
                Funktionalität unseres Internetauftritts.
              </p>
              <p className="text-muted">
                Durch die so hergestellte Verbindung zu Google kann Google
                ermitteln, von welcher Website Ihre Anfrage gesendet worden ist
                und an welche IP-Adresse die Anfahrtsbeschreibung zu übermitteln
                ist.
              </p>
              <p className="text-muted">
                Sofern Sie mit dieser Verarbeitung nicht einverstanden sind,
                haben Sie die Möglichkeit, die Installation der Cookies durch
                die entsprechenden Einstellungen in Ihrem Internet-Browser zu
                verhindern. Einzelheiten hierzu finden Sie vorstehend unter dem
                Punkt „Cookies“.
              </p>
              <p className="text-muted">
                Zudem erfolgt die Nutzung von Google Maps sowie der über Google
                Maps erlangten Informationen nach den{" "}
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="http://www.google.de/accounts/TOS"
                >
                  Google-Nutzungsbedingungen
                </a>
                &nbsp;
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="https://policies.google.com/terms?gl=DE&amp;hl=de"
                >
                  https://policies.google.com/terms?gl=DE&amp;hl=de
                </a>{" "}
                und den{" "}
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="http://www.google.com/intl/de_de/help/terms_maps.html"
                >
                  Geschäftsbedingungen für Google Maps
                </a>{" "}
                https://www.google.com/intl/de_de/help/terms_maps.html.
              </p>
              <p className="text-muted">Überdies bietet Google unter</p>
              <p className="text-muted">
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="https://adssettings.google.com/authenticated"
                >
                  https://adssettings.google.com/authenticated
                </a>
              </p>
              <p className="text-muted">
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="https://policies.google.com/privacy"
                >
                  https://policies.google.com/privacy
                </a>
              </p>
              <p className="text-muted">weitergehende Informationen an.</p>
              <h4>Google reCAPTCHA</h4>
              <p className="text-muted">
                In unserem Internetauftritt setzen wir Google reCAPTCHA zur
                Überprüfung und Vermeidung von Interaktionen auf unserer
                Internetseite durch automatisierte Zugriffe, bspw. durch sog.
                Bots, ein. Es handelt sich hierbei um einen Dienst der Google
                LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA,
                nachfolgend nur „Google“ genannt.
              </p>
              <p className="text-muted">
                Durch die Zertifizierung nach dem EU-US-Datenschutzschild
                („EU-US Privacy Shield“)
              </p>
              <p className="text-muted">
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"
                >
                  https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
                </a>
              </p>
              <p className="text-muted">
                garantiert Google, dass die Datenschutzvorgaben der EU auch bei
                der Verarbeitung von Daten in den USA eingehalten werden.
              </p>
              <p className="text-muted">
                Durch diesen Dienst kann Google ermitteln, von welcher Webseite
                eine Anfrage gesendet wird sowie von welcher IP-Adresse aus Sie
                die sog. reCAPTCHA-Eingabebox verwenden. Neben Ihrer IP-Adresse
                werden womöglich noch weitere Informationen durch Google
                erfasst, die für das Angebot und die Gewährleistung dieses
                Dienstes notwendig sind.&nbsp;&nbsp;&nbsp;
              </p>
              <p className="text-muted">
                Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser
                berechtigtes Interesse liegt in der Sicherheit unseres
                Internetauftritts sowie in der Abwehr unerwünschter,
                automatisierter Zugriffe in Form von Spam o.ä..
              </p>
              <p className="text-muted">Google bietet unter</p>
              <p className="text-muted">
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="https://policies.google.com/privacy"
                >
                  https://policies.google.com/privacy
                </a>
              </p>
              <p className="text-muted">
                weitergehende Informationen zu dem allgemeinen Umgang mit Ihren
                Nutzerdaten an.
              </p>
              <h4>Google Fonts</h4>
              <p className="text-muted">
                In unserem Internetauftritt setzen wir Google Fonts zur
                Darstellung externer Schriftarten ein. Es handelt sich hierbei
                um einen Dienst der Google LLC, 1600 Amphitheatre Parkway,
                Mountain View, CA 94043 USA, nachfolgend nur „Google“ genannt.
              </p>
              <p className="text-muted">
                Durch die Zertifizierung nach dem EU-US-Datenschutzschild
                („EU-US Privacy Shield“)
              </p>
              <p className="text-muted">
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"
                >
                  https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
                </a>
              </p>
              <p className="text-muted">
                garantiert Google, dass die Datenschutzvorgaben der EU auch bei
                der Verarbeitung von Daten in den USA eingehalten werden.
              </p>
              <p className="text-muted">
                Um die Darstellung bestimmter Schriften in unserem
                Internetauftritt zu ermöglichen, wird bei Aufruf unseres
                Internetauftritts eine Verbindung zu dem Google-Server in den
                USA aufgebaut.
              </p>
              <p className="text-muted">
                Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser
                berechtigtes Interesse liegt in der Optimierung und dem
                wirtschaftlichen Betrieb unseres Internetauftritts.
              </p>
              <p className="text-muted">
                Durch die bei Aufruf unseres Internetauftritts hergestellte
                Verbindung zu Google kann Google ermitteln, von welcher Website
                Ihre Anfrage gesendet worden ist und an welche IP-Adresse die
                Darstellung der Schrift zu übermitteln ist.
              </p>
              <p className="text-muted">Google bietet unter</p>
              <p className="text-muted">
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="https://adssettings.google.com/authenticated"
                >
                  https://adssettings.google.com/authenticated
                </a>
              </p>
              <p className="text-muted">
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="https://policies.google.com/privacy"
                >
                  https://policies.google.com/privacy
                </a>
              </p>
              <p className="text-muted">
                weitere Informationen an und zwar insbesondere zu den
                Möglichkeiten der Unterbindung der Datennutzung.
              </p>
              <h4>„Facebook“-Social-Plug-in</h4>
              <p className="text-muted">
                In unserem Internetauftritt setzen wir das Plug-in des
                Social-Networks Facebook ein. Bei Facebook handelt es sich um
                einen Internetservice der facebook Inc., 1601 S. California Ave,
                Palo Alto, CA 94304, USA. In der EU wird dieser Service wiederum
                von der Facebook Ireland Limited, 4 Grand Canal Square, Dublin
                2, Irland, betrieben, nachfolgend beide nur „Facebook“ genannt.
              </p>
              <p className="text-muted">
                Durch die Zertifizierung nach dem EU-US-Datenschutzschild
                („EU-US Privacy Shield“)
              </p>
              <p className="text-muted">
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active"
                >
                  https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active
                </a>
              </p>
              <p className="text-muted">
                garantiert Facebook, dass die Datenschutzvorgaben der EU auch
                bei der Verarbeitung von Daten in den USA eingehalten werden.
              </p>
              <p className="text-muted">
                Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser
                berechtigtes Interesse liegt in der Qualitätsverbesserung
                unseres Internetauftritts.
              </p>
              <p className="text-muted">
                Weitergehende Informationen über die möglichen Plug-ins sowie
                über deren jeweilige Funktionen hält Facebook unter
              </p>
              <p className="text-muted">
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="https://developers.facebook.com/docs/plugins/"
                >
                  https://developers.facebook.com/docs/plugins/
                </a>
              </p>
              <p className="text-muted">für Sie bereit.</p>
              <p className="text-muted">
                Sofern das Plug-in auf einer der von Ihnen besuchten Seiten
                unseres Internetauftritts hinterlegt ist, lädt Ihr
                Internet-Browser eine Darstellung des Plug-ins von den Servern
                von Facebook in den USA herunter. Aus technischen Gründen ist es
                dabei notwendig, dass Facebook Ihre IP-Adresse verarbeitet.
                Daneben werden aber auch Datum und Uhrzeit des Besuchs unserer
                Internetseiten erfasst.
              </p>
              <p className="text-muted">
                Sollten Sie bei Facebook eingeloggt sein, während Sie eine
                unserer mit dem Plug-in versehenen Internetseite besuchen,
                werden die durch das Plug-in gesammelten Informationen Ihres
                konkreten Besuchs von Facebook erkannt. Die so gesammelten
                Informationen weist Facebook womöglich Ihrem dortigen
                persönlichen Nutzerkonto zu. Sofern Sie also bspw. den sog.
                „Gefällt mir“-Button von Facebook benutzen, werden diese
                Informationen in Ihrem Facebook-Nutzerkonto gespeichert und ggf.
                über die Plattform von Facebook veröffentlicht. Wenn Sie das
                verhindern möchten, müssen Sie sich entweder vor dem Besuch
                unseres Internetauftritts bei Facebook ausloggen oder durch den
                Einsatz eines Add-ons für Ihren Internetbrowser verhindern, dass
                das Laden des Facebook-Plug-in blockiert wird.
              </p>
              <p className="text-muted">
                Weitergehende Informationen über die Erhebung und Nutzung von
                Daten sowie Ihre diesbezüglichen Rechte und Schutzmöglichkeiten
                hält Facebook in den unter
              </p>
              <p className="text-muted">
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="https://www.facebook.com/policy.php"
                >
                  https://www.facebook.com/policy.php
                </a>
              </p>
              <p className="text-muted">
                abrufbaren Datenschutzhinweisen bereit.
              </p>
              <h4>YouTube</h4>
              <p className="text-muted">
                In unserem Internetauftritt setzen wir YouTube ein. Hierbei
                handelt es sich um ein Videoportal der YouTube LLC., 901 Cherry
                Ave., 94066 San Bruno, CA, USA, nachfolgend nur „YouTube“
                genannt.
              </p>
              <p className="text-muted">
                YouTube ist ein Tochterunternehmen der Google LLC., 1600
                Amphitheatre Parkway, Mountain View, CA 94043 USA, nachfolgend
                nur „Google“ genannt.
              </p>
              <p className="text-muted">
                Durch die Zertifizierung nach dem EU-US-Datenschutzschild
                („EU-US Privacy Shield“)
              </p>
              <p className="text-muted">
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"
                >
                  https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
                </a>
              </p>
              <p className="text-muted">
                garantiert Google und damit auch das Tochterunternehmen YouTube,
                dass die Datenschutzvorgaben der EU auch bei der Verarbeitung
                von Daten in den USA eingehalten werden.
              </p>
              <p className="text-muted">
                Wir nutzen YouTube im Zusammenhang mit der Funktion „Erweiterter
                Datenschutzmodus“, um Ihnen Videos anzeigen zu können.
                Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser
                berechtigtes Interesse liegt in der Qualitätsverbesserung
                unseres Internetauftritts. Die Funktion „Erweiterter
                Datenschutzmodus“ bewirkt laut Angaben von YouTube, dass die
                nachfolgend noch näher bezeichneten Daten nur dann an den Server
                von YouTube übermittelt werden, wenn Sie ein Video auch
                tatsächlich starten.
              </p>
              <p className="text-muted">
                Ohne diesen „Erweiterten Datenschutz“ wird eine Verbindung zum
                Server von YouTube in den USA hergestellt, sobald Sie eine
                unserer Internetseiten, auf der ein YouTube-Video eingebettet
                ist, aufrufen.
              </p>
              <p className="text-muted">
                Diese Verbindung ist erforderlich, um das jeweilige Video auf
                unserer Internetseite über Ihren Internet-Browser darstellen zu
                können. Im Zuge dessen wird YouTube zumindest Ihre IP-Adresse,
                das Datum nebst Uhrzeit sowie die von Ihnen besuchte
                Internetseite erfassen und verarbeiten. Zudem wird eine
                Verbindung zu dem Werbenetzwerk „DoubleClick“ von Google
                hergestellt.
              </p>
              <p className="text-muted">
                Sollten Sie gleichzeitig bei YouTube eingeloggt sein, weist
                YouTube die Verbindungsinformationen Ihrem YouTube-Konto zu.
                Wenn Sie das verhindern möchten, müssen Sie sich entweder vor
                dem Besuch unseres Internetauftritts bei YouTube ausloggen oder
                die entsprechenden Einstellungen in Ihrem YouTube-Benutzerkonto
                vornehmen.
              </p>
              <p className="text-muted">
                Zum Zwecke der Funktionalität sowie zur Analyse des
                Nutzungsverhaltens speichert YouTube dauerhaft Cookies über
                Ihren Internet-Browser auf Ihrem Endgerät. Falls Sie mit dieser
                Verarbeitung nicht einverstanden sind, haben Sie die
                Möglichkeit, die Speicherung der Cookies durch eine Einstellung
                in Ihrem Internet-Browsers zu verhindern. Nähere Informationen
                hierzu finden Sie vorstehend unter „Cookies“.
              </p>
              <p className="text-muted">
                Weitergehende Informationen über die Erhebung und Nutzung von
                Daten sowie Ihre diesbezüglichen Rechte und Schutzmöglichkeiten
                hält Google in den unter
              </p>
              <p className="text-muted">
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="https://policies.google.com/privacy"
                >
                  https://policies.google.com/privacy
                </a>
              </p>
              <p className="text-muted">
                abrufbaren Datenschutzhinweisen bereit.
              </p>
              <h4>MailChimp - Newsletter</h4>
              <p className="text-muted">
                Wir bieten Ihnen die Möglichkeit an, sich bei uns über unseren
                Internetauftritt für unsere kostenlosen Newsletter anmelden zu
                können.
              </p>
              <p className="text-muted">
                Zum Newsletterversand setzen wir MailChimp, einen Dienst der The
                Rocket Science Group, LLC, 512 Means Street, Suite 404, Atlanta,
                GA 30318, USA, nachfolgend nur „The Rocket Science Group“
                genannt, ein.
              </p>
              <p className="text-muted">
                Durch die Zertifizierung nach dem EU-US-Datenschutzschild
                („EU-US Privacy Shield“)
              </p>
              <p className="text-muted">
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="https://www.privacyshield.gov/participant?id=a2zt0000000TO6hAAG&amp;status=Active"
                >
                  https://www.privacyshield.gov/participant?id=a2zt0000000TO6hAAG&amp;status=Active
                </a>
              </p>
              <p className="text-muted">
                garantiert The Rocket Science Group, dass die
                Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten
                in den USA eingehalten werden. Zudem bietet The Rocket Science
                Group unter
              </p>
              <p className="text-muted">
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="http://mailchimp.com/legal/privacy/"
                >
                  http://mailchimp.com/legal/privacy/
                </a>
              </p>
              <p className="text-muted">
                weitergehende Datenschutzinformationen an.
              </p>
              <p className="text-muted">
                Falls Sie sich zu unserem Newsletter-Versand anmelden, werden
                die während des Anmeldevorgangs abgefragten Daten, wie Ihre
                E-Mail-Adresse sowie, optional, Ihr Name nebst Anschrift, durch
                The Rocket Science Group verarbeitet. Zudem werden Ihre
                IP-Adresse sowie das Datum Ihrer Anmeldung nebst Uhrzeit
                gespeichert. Im Rahmen des weiteren Anmeldevorgangs wird Ihre
                Einwilligung in die Übersendung des Newsletters eingeholt, der
                Inhalt konkret beschreiben und auf diese Datenschutzerklärung
                verwiesen.
              </p>
              <p className="text-muted">
                Der anschließend über The Rocket Science Group versandte
                Newsletter enthält zudem einen sog. Zählpixel, auch Web Beacon
                genannt“. Mit Hilfe dieses Zählpixels können wir auswerten, ob
                und wann Sie unseren Newsletter gelesen haben und ob Sie den in
                dem Newsletter etwaig enthaltenen weiterführenden Links gefolgt
                sind. Neben weiteren technischen Daten, wie bspw. die Daten
                Ihres EDV-Systems und Ihre IP-Adresse, werden die dabei
                verarbeiteten Daten gespeichert, damit wir unser
                Newsletter-Angebot optimieren und auf die Wünsche der Leser
                eingehen können. Die Daten werden also zur Steigerung der
                Qualität und Attraktivität unseres Newsletter-Angebots zu
                steigern.
              </p>
              <p className="text-muted">
                Rechtsgrundlage für den Versand des Newsletters und die Analyse
                ist Art. 6 Abs. 1 lit. a.) DSGVO.
              </p>
              <p className="text-muted">
                Die Einwilligung in den Newsletter-Versand können Sie gemäß Art.
                7 Abs. 3 DSGVO jederzeit mit Wirkung für die Zukunft widerrufen.
                Hierzu müssen Sie uns lediglich über Ihren Widerruf in Kenntnis
                setzen oder den in jedem Newsletter enthaltenen Abmeldelink
                betätigen.
              </p>
              <h4>Google AdWords mit Conversion-Tracking</h4>
              <p className="text-muted">
                In unserem Internetauftritt setzen wir die Werbe-Komponente
                Google AdWords und dabei das sog. Conversion-Tracking ein. Es
                handelt sich hierbei um einen Dienst der Google LLC, 1600
                Amphitheatre Parkway, Mountain View, CA 94043 USA, nachfolgend
                nur „Google“ genannt.
              </p>
              <p className="text-muted">
                Durch die Zertifizierung nach dem EU-US-Datenschutzschild
                („EU-US Privacy Shield“)
              </p>
              <p className="text-muted">
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"
                >
                  https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
                </a>
              </p>
              <p className="text-muted">
                garantiert Google, dass die Datenschutzvorgaben der EU auch bei
                der Verarbeitung von Daten in den USA eingehalten werden.
              </p>
              <p className="text-muted">
                Wir nutzen das Conversion-Tracking zur zielgerichteten Bewerbung
                unseres Angebots. Rechtsgrundlage ist Art. 6 Abs. 1 lit. f)
                DSGVO. Unser berechtigtes Interesse liegt in der Analyse,
                Optimierung und dem wirtschaftlichen Betrieb unseres
                Internetauftritts.
              </p>
              <p className="text-muted">
                Falls Sie auf eine von Google geschaltete Anzeige klicken,
                speichert das von uns eingesetzte Conversion-Tracking ein Cookie
                auf Ihrem Endgerät. Diese sog. Conversion-Cookies verlieren mit
                Ablauf von 30 Tagen ihre Gültigkeit und dienen im Übrigen nicht
                Ihrer persönlichen Identifikation.
              </p>
              <p className="text-muted">
                Sofern das Cookie noch gültig ist und Sie eine bestimmte Seite
                unseres Internetauftritts besuchen, können sowohl wir als auch
                Google auswerten, dass Sie auf eine unserer bei Google
                platzierten Anzeigen geklickt haben und dass Sie anschließend
                auf unseren Internetauftritt weitergeleitet worden sind.
              </p>
              <p className="text-muted">
                Durch die so eingeholten Informationen erstellt Google uns eine
                Statistik über den Besuch unseres Internetauftritts. Zudem
                erhalten wir hierdurch Informationen über die Anzahl der Nutzer,
                die auf unsere Anzeige(n) geklickt haben sowie über die
                anschließend aufgerufenen Seiten unseres Internetauftritts.
                Weder wir noch Dritte, die ebenfalls Google-AdWords einsetzten,
                werden hierdurch allerdings in die Lage versetzt, Sie auf diesem
                Wege zu identifizieren.
              </p>
              <p className="text-muted">
                Durch die entsprechenden Einstellungen Ihres Internet-Browsers
                können Sie zudem die Installation der Cookies verhindern oder
                einschränken. Gleichzeitig können Sie bereits gespeicherte
                Cookies jederzeit löschen. Die hierfür erforderlichen Schritte
                und Maßnahmen hängen jedoch von Ihrem konkret genutzten
                Internet-Browser ab. Bei Fragen benutzen Sie daher bitte die
                Hilfefunktion oder Dokumentation Ihres Internet-Browsers oder
                wenden sich an dessen Hersteller bzw. Support.
              </p>
              <p className="text-muted">Ferner bietet auch Google unter</p>
              <p className="text-muted">
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="https://services.google.com/sitestats/de.html"
                >
                  https://services.google.com/sitestats/de.html
                </a>
              </p>
              <p className="text-muted">
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="http://www.google.com/policies/technologies/ads/"
                >
                  http://www.google.com/policies/technologies/ads/
                </a>
                &nbsp;
              </p>
              <p className="text-muted">
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="http://www.google.de/policies/privacy/"
                >
                  http://www.google.de/policies/privacy/
                </a>
              </p>
              <p className="text-muted">
                weitergehende Informationen zu diesem Thema und dabei
                insbesondere zu den Möglichkeiten der Unterbindung der
                Datennutzung an.
              </p>
              <h4>Google AdSense</h4>
              <p className="text-muted">
                In unserem Internetauftritt setzen wir zur Einbindung von
                Werbeanzeigen Google AdSense ein. Es handelt sich hierbei um
                einen Dienst der Google LLC, 1600 Amphitheatre Parkway, Mountain
                View, CA 94043 USA, nachfolgend nur „Google“ genannt.
              </p>
              <p className="text-muted">
                Durch die Zertifizierung nach dem EU-US-Datenschutzschild
                („EU-US Privacy Shield“)
              </p>
              <p className="text-muted">
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"
                >
                  https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
                </a>
              </p>
              <p className="text-muted">
                garantiert Google, dass die Datenschutzvorgaben der EU auch bei
                der Verarbeitung von Daten in den USA eingehalten werden.
              </p>
              <p className="text-muted">
                Durch Google AdSense werden Cookies sowie sog. Web Beacons über
                Ihren Internet-Browser auf Ihrem Endgerät gespeichert. Hierdurch
                ermöglicht uns Google die Analyse der Nutzung unseres
                Internetauftritts durch Sie. Die so erfassten Informationen
                werden neben Ihrer IP-Adresse und der Ihnen angezeigten
                Werbeformate an Google in die USA übertragen und dort
                gespeichert. Ferner kann Google diese Informationen an
                Vertragspartner weitergeben. Google erklärt allerdings, dass
                Ihre IP-Adresse nicht mit anderen Daten von Ihnen
                zusammengeführt würden.
              </p>
              <p className="text-muted">
                Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser
                berechtigtes Interesse liegt in der Analyse, Optimierung und dem
                wirtschaftlichen Betrieb unseres Internetauftritts.
              </p>
              <p className="text-muted">
                Sofern Sie mit dieser Verarbeitung nicht einverstanden sind,
                haben Sie die Möglichkeit, die Installation der Cookies durch
                die entsprechenden Einstellungen in Ihrem Internet-Browser zu
                verhindern. Einzelheiten hierzu finden Sie vorstehend unter dem
                Punkt „Cookies“.
              </p>
              <p className="text-muted">Zudem bietet Google unter</p>
              <p className="text-muted">
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="https://policies.google.com/privacy"
                >
                  https://policies.google.com/privacy
                </a>
              </p>
              <p className="text-muted">
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="https://adssettings.google.com/authenticated"
                >
                  https://adssettings.google.com/authenticated
                </a>
              </p>
              <p className="text-muted">
                weitere Informationen an und zwar insbesondere zu den
                Möglichkeiten der Unterbindung der Datennutzung.
              </p>
              <h4>
                Google Remarketing oder "Ähnliche Zielgruppen"-Komponente von
                Google
              </h4>
              <p className="text-muted">
                In unserem Internetauftritt setzen wir die Remarketing- oder
                „Ähnliche Zielgruppen“-Funktion ein. Es handelt sich hierbei um
                einen Dienst der Google LLC, 1600 Amphitheatre Parkway, Mountain
                View, CA 94043 USA, nachfolgend nur „Google“ genannt.
              </p>
              <p className="text-muted">
                Durch die Zertifizierung nach dem EU-US-Datenschutzschild
                („EU-US Privacy Shield“)
              </p>
              <p className="text-muted">
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"
                >
                  https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
                </a>
              </p>
              <p className="text-muted">
                garantiert Google, dass die Datenschutzvorgaben der EU auch bei
                der Verarbeitung von Daten in den USA eingehalten werden.
              </p>
              <p className="text-muted">
                Wir nutzen diese Funktion, um interessenbezogene,
                personalisierte Werbung auf Internetseiten Dritter, die
                ebenfalls an dem Werbe-Netzwerk von Google teilnehmen, zu
                schalten.
              </p>
              <p className="text-muted">
                Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser
                berechtigtes Interesse liegt in der Analyse, Optimierung und dem
                wirtschaftlichen Betrieb unseres Internetauftritts.
              </p>
              <p className="text-muted">
                Damit dieser Werbe-Dienst ermöglicht werden kann, speichert
                Google während Ihres Besuchs unseres Internetauftritts über
                Ihren Internet-Browser ein Cookie mit einer Zahlenfolge auf
                Ihrem Endgerät. Dieses Cookie erfasst in anonymisierter Form
                sowohl Ihren Besuch als auch die Nutzung unseres
                Internetauftritts. Personenbezogene Daten wird dabei allerdings
                nicht weitergegeben. Sofern Sie anschließend den
                Internetauftritt eines Dritten besuchen, der seinerseits
                ebenfalls das Werbe-Netzwerk von Google nutzt, werden womöglich
                Werbeeinblendungen erscheinen, die einen Bezug zu unserem
                Internetauftritt bzw. zu unseren dortigen Angeboten aufweisen.
              </p>
              <p className="text-muted">
                Zur dauerhaften Deaktivierung dieser Funktion bietet Google für
                die gängigsten Internet-Browser über
              </p>
              <p className="text-muted">
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="https://www.google.com/settings/ads/plugin"
                >
                  https://www.google.com/settings/ads/plugin
                </a>
              </p>
              <p className="text-muted">ein Browser-Plugin an.</p>
              <p className="text-muted">
                Ebenfalls kann die Verwendung von Cookies bestimmter Anbieter
                bspw. über
              </p>
              <p className="text-muted">
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="http://www.youronlinechoices.com/uk/your-ad-choices"
                >
                  http://www.youronlinechoices.com/uk/your-ad-choices
                </a>
              </p>
              <p className="text-muted">oder</p>
              <p className="text-muted">
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="http://www.networkadvertising.org/choices/"
                >
                  http://www.networkadvertising.org/choices/
                </a>
              </p>
              <p className="text-muted">per Opt-out deaktiviert werden.</p>
              <p className="text-muted">
                Durch das sog. <strong>Cross-Device-Marketing</strong> kann
                Google Ihr Nutzungsverhalten unter Umständen auch über mehrere
                Endgeräte hinweg verfolgen, sodass Ihnen womöglich selbst dann
                interessenbezogene, personalisierte Werbung angezeigt wird, wenn
                Sie das Endgerät wechseln. Dies setzt allerdings voraus, dass
                Sie der Verknüpfung Ihrer Browserverläufe mit Ihrem bestehenden
                Google-Konto zugestimmt haben.
              </p>
              <p className="text-muted">
                Google bietet weitergehende Informationen zu Google Remarketing
                unter
              </p>
              <p className="text-muted">
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="http://www.google.com/privacy/ads/"
                >
                  http://www.google.com/privacy/ads/
                </a>
              </p>
              <p className="text-muted">an.</p>
              <h4>Newsletter per WhatsApp</h4>
              <p className="text-muted">
                Unseren kostenlosen Newsletter können Sie auch über den
                Instant-Messaging-Dienst WhatsApp beziehen. WhatsApp ist ein
                Dienst der WhatsApp Ireland Limited, 4 Grand Canal Square, Grand
                Canal Harbour, Dublin 2, Irland, einem Tochterunternehmen der
                WhatsApp Inc., 1601 Willow Road, Menlo Park, California 94025,
                USA, beide nachfolgend nur „WhatsApp“ genannt. Teilweise erfolgt
                die Verarbeitung der Nutzerdaten auf Servern von WhatsApp in den
                USA.Durch die Zertifizierung nach dem EU-US-Datenschutzschild
                („EU-US Privacy Shield“)
              </p>
              <p className="text-muted">
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="https://www.privacyshield.gov/participant?id=a2zt0000000TSnwAAG&amp;status=Active"
                >
                  https://www.privacyshield.gov/participant?id=a2zt0000000TSnwAAG&amp;status=Active
                </a>
              </p>
              <p className="text-muted">
                garantiert WhatsApp aber, dass die Datenschutzvorgaben der EU
                auch bei der Verarbeitung von Daten in den USA eingehalten
                werden. Zudem bietet WhatsApp unter
              </p>
              <p className="text-muted">
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="https://www.whatsapp.com/legal/#privacy-policy"
                >
                  https://www.whatsapp.com/legal/#privacy-policy
                </a>
              </p>
              <p className="text-muted">
                weitergehende Datenschutzinformationen an
              </p>
              <p className="text-muted">
                Um unseren Newsletter per WhatsApp empfangen zu können,
                benötigen Sie ein WhatsApp Nutzerkonto. Einzelheiten darüber,
                welche Daten WhatsApp bei der Registrierung erhebt, können Sie
                den vorgenannten Datenschutzinformationen von WhatsApp
                entnehmen.
              </p>
              <p className="text-muted">
                Falls Sie sich dann zu unserem Newsletter-Versand per WhatsApp
                anmelden, wird die von Ihnen während des Anmeldevorgangs
                eingegebene Mobilfunkrufnummer durch WhatsApp verarbeitet. Zudem
                werden Ihre IP-Adresse sowie das Datum Ihrer Anmeldung nebst
                Uhrzeit gespeichert. Im Rahmen des weiteren Anmeldevorgangs wird
                Ihre Einwilligung in die Übersendung des Newsletters eingeholt,
                der Inhalt konkret beschreiben und auf diese
                Datenschutzerklärung verwiesen.
              </p>
              <p className="text-muted">
                Rechtsgrundlage für den Versand des Newsletters und die Analyse
                ist Art. 6 Abs. 1 lit. a.) DSGVO.
              </p>
              <p className="text-muted">
                Die Einwilligung in den Newsletter-Versand können Sie gemäß Art.
                7 Abs. 3 DSGVO jederzeit mit Wirkung für die Zukunft widerrufen.
                Hierzu müssen Sie uns lediglich über Ihren Widerruf in Kenntnis
                setzen. Ebenfalls können Sie den Newsletterempfang durch eine
                Einstellung in der WhatsApp-Software auf Ihrem Endgerät
                blockieren.
              </p>
              <h4>Gewinnspiel</h4>
              <p className="text-muted">
                Über unseren Internetauftritt bieten wir Ihnen die Teilnahme an
                Gewinnspielen an. Sofern Sie an einem unserer Gewinnspiele
                teilnehmen, werden die von Ihnen anlässlich der Teilnahme
                eingegebenen Daten ohne Ihre weitergehende Einwilligung, aber
                natürlich ausschließlich nur zur Durchführung und Abwicklung des
                jeweiligen Gewinnspiels, verarbeitet.
              </p>
              <p className="text-muted">
                Im Rahmen der Abwicklung des Gewinnspiels geben wir Ihre Daten
                an das mit der Warenlieferung beauftragte Transportunternehmen
                oder an einen Finanzdienstleister weiter, soweit die Weitergabe
                zur Lieferung bzw. Auszahlung Ihres Gewinns erforderlich ist.
                Sofern Ihre Daten im Falle eines Gewinns veröffentlicht werden,
                werden Sie hierauf im Rahmen der Einwilligungserklärung
                hingewiesen.
              </p>
              <p className="text-muted">
                Rechtsgrundlage für die Weitergabe der Daten ist dann Art. 6
                Abs. 1 lit. b) DSGVO.
              </p>
              <p className="text-muted">
                Ihre Einwilligung in die Verarbeitung der Daten zur Teilnahme an
                unseren Gewinnspielen können Sie gemäß Art. 7 Abs. 3 DSGVO
                jederzeit mit Wirkung für die Zukunft widerrufen. Hierzu müssen
                Sie uns lediglich über Ihren Widerruf in Kenntnis setzen.
              </p>
              <h4>
                Online-Stellenbewerbungen / Veröffentlichung von Stellenanzeigen
              </h4>
              <p className="text-muted">
                Wir bieten Ihnen die Möglichkeit an, sich bei uns über unseren
                Internetauftritt bewerben zu können. Bei diesen digitalen
                Bewerbungen werden Ihre Bewerber- und Bewerbungsdaten von uns
                zur Abwicklung des Bewerbungsverfahrens elektronisch erhoben und
                verarbeitet.
              </p>
              <p className="text-muted">
                Rechtsgrundlage für diese Verarbeitung ist § 26 Abs. 1 S. 1 BDSG
                i.V.m. Art. 88 Abs. 1 DSGVO.
              </p>
              <p className="text-muted">
                Sofern nach dem Bewerbungsverfahren ein Arbeitsvertrag
                geschlossen wird, speichern wir Ihre bei der Bewerbung
                übermittelten Daten in Ihrer Personalakte zum Zwecke des
                üblichen Organisations- und Verwaltungsprozesses – dies
                natürlich unter Beachtung der weitergehenden rechtlichen
                Verpflichtungen.
              </p>
              <p className="text-muted">
                Rechtsgrundlage für diese Verarbeitung ist ebenfalls § 26 Abs. 1
                S. 1 BDSG i.V.m. Art. 88 Abs. 1 DSGVO.
              </p>
              <p className="text-muted">
                Bei der Zurückweisung einer Bewerbung löschen wir die uns
                übermittelten Daten automatisch zwei Monate nach der Bekanntgabe
                der Zurückweisung. Die Löschung erfolgt jedoch nicht, wenn die
                Daten aufgrund gesetzlicher Bestimmungen, bspw. wegen der
                Beweispflichten nach dem AGG, eine längere Speicherung von bis
                zu vier Monaten oder bis zum Abschluss eines gerichtlichen
                Verfahrens erfordern.
              </p>
              <p className="text-muted">
                Rechtsgrundlage ist in diesem Fall Art. 6 Abs. 1 lit. f) DSGVO
                und § 24 Abs. 1 Nr. 2 BDSG. Unser berechtigtes Interesse liegt
                in der Rechtsverteidigung bzw. -durchsetzung.
              </p>
              <p className="text-muted">
                Sofern Sie ausdrücklich in eine längere Speicherung Ihrer Daten
                einwilligen, bspw. für Ihre Aufnahme in eine Bewerber- oder
                Interessentendatenbank, werden die Daten aufgrund Ihrer
                Einwilligung weiterverarbeitet. Rechtsgrundlage ist dann Art. 6
                Abs. 1 lit. a) DSGVO. Ihre Einwilligung können Sie aber
                natürlich jederzeit nach Art. 7 Abs. 3 DSGVO durch Erklärung uns
                gegenüber mit Wirkung für die Zukunft widerrufen.
              </p>
              <p className="text-muted"></p>
              <hr />
              <h2>Privacy Policy</h2>
              <p className="text-muted">
                Personal data (usually referred to just as "data" below) will
                only be processed by us to the extent necessary and for the
                purpose of providing a functional and user-friendly website,
                including its contents, and the services offered there.
              </p>
              <p className="text-muted">
                Per Art. 4 No. 1 of Regulation (EU) 2016/679, i.e. the General
                Data Protection Regulation (hereinafter referred to as the
                "GDPR"), "processing" refers to any operation or set of
                operations such as collection, recording, organization,
                structuring, storage, adaptation, alteration, retrieval,
                consultation, use, disclosure by transmission, dissemination, or
                otherwise making available, alignment, or combination,
                restriction, erasure, or destruction performed on personal data,
                whether by automated means or not.
              </p>
              <p className="text-muted">
                The following privacy policy is intended to inform you in
                particular about the type, scope, purpose, duration, and legal
                basis for the processing of such data either under our own
                control or in conjunction with others. We also inform you below
                about the third-party components we use to optimize our website
                and improve the user experience which may result in said third
                parties also processing data they collect and control.
              </p>
              <p className="text-muted">
                Our privacy policy is structured as follows:
              </p>
              <p className="text-muted">
                I. Information about us as controllers of your data
                <br />
                II. The rights of users and data subjects
                <br />
                III. Information about the data processing
              </p>
              <h3>I. Information about us as controllers of your data</h3>
              <p className="text-muted">
                The party responsible for this website (the "controller") for
                purposes of data protection law is:
              </p>
              <p className="text-muted">
                Albino Cipolla & Tobias Brauchle
                <br />
                Nahestraße 5<br />
                55118 Mainz
                <br />
                Germany
              </p>
              <p className="text-muted">
                Telephone: +49 800 100 10 01
                <br />
                E-Mail: datenschutz@maklerzentrale.com
              </p>
              <h3>II. The rights of users and data subjects</h3>
              <p className="text-muted">
                With regard to the data processing to be described in more
                detail below, users and data subjects have the right
              </p>
              <ul type="disc" className="text-muted">
                <li>
                  to confirmation of whether data concerning them is being
                  processed, information about the data being processed, further
                  information about the nature of the data processing, and
                  copies of the data (cf. also Art. 15 GDPR);
                </li>
                <li>
                  to correct or complete incorrect or incomplete data (cf. also
                  Art. 16 GDPR);
                </li>
                <li>
                  to the immediate deletion of data concerning them (cf. also
                  Art. 17 DSGVO), or, alternatively, if further processing is
                  necessary as stipulated in Art. 17 Para. 3 GDPR, to restrict
                  said processing per Art. 18 GDPR;
                </li>
                <li>
                  to receive copies of the data concerning them and/or provided
                  by them and to have the same transmitted to other
                  providers/controllers (cf. also Art. 20 GDPR);
                </li>
                <li>
                  to file complaints with the supervisory authority if they
                  believe that data concerning them is being processed by the
                  controller in breach of data protection provisions (see also
                  Art. 77 GDPR).
                </li>
              </ul>
              <p className="text-muted">
                In addition, the controller is obliged to inform all recipients
                to whom it discloses data of any such corrections, deletions, or
                restrictions placed on processing the same per Art. 16, 17 Para.
                1, 18 GDPR. However, this obligation does not apply if such
                notification is impossible or involves a disproportionate
                effort. Nevertheless, users have a right to information about
                these recipients.
              </p>
              <p className="text-muted">
                <strong>
                  Likewise, under Art. 21 GDPR, users and data subjects have the
                  right to object to the controller's future processing of their
                  data pursuant to Art. 6 Para. 1 lit. f) GDPR. In particular,
                  an objection to data processing for the purpose of direct
                  advertising is permissible.
                </strong>
              </p>
              <h3>III. Information about the data processing</h3>
              <p className="text-muted">
                Your data processed when using our website will be deleted or
                blocked as soon as the purpose for its storage ceases to apply,
                provided the deletion of the same is not in breach of any
                statutory storage obligations or unless otherwise stipulated
                below.
              </p>
              <a
                target="_blank"
                href="https://www.ratgeberrecht.eu/leistungen/muster-datenschutzerklaerung.html"
                rel="nofollow noopener noreferrer"
              >
                Datenschutzerklärung
              </a>{" "}
              der{" "}
              <a
                target="_blank"
                href="https://www.ratgeberrecht.eu/"
                rel="nofollow noopener noreferrer"
              >
                Anwaltskanzlei Weiß &amp; Partner
              </a>
            </div>
            <div className="col-12 col-md-4 mb-5">
              <div className="card shadow-light-lg">
                <div className="card-body">
                  <h4>Kontakt</h4>
                  {/*<p className="fs-sm text-muted mb-5">*/}
                  {/*  Not sure exactly what we’re looking for or just want clarification? We’d be happy to chat with you*/}
                  {/*  and clear things up for you. Anytime!*/}
                  {/*</p>*/}
                  <h6 className="fw-bold text-uppercase text-secondary mb-2">
                    Telefon
                  </h6>
                  <p className="fs-sm mb-5">
                    <a href="tel:+498002242020" className="text-reset">
                      0800 2242020
                    </a>
                  </p>
                  <h6 className="fw-bold text-uppercase text-secondary mb-2">
                    E-Mail
                  </h6>
                  <p className="fs-sm mb-0">
                    <a
                      href="mailto:datenschutz@maklerzentrale.com"
                      className="text-reset"
                    >
                      datenschutz@maklerzentrale.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Datenschutz
